import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userDetailInhouse: JSON.parse(window.localStorage.getItem('userDetailInhouse')),
};

const localSlice = createSlice({
    name: 'userDetailInhouse',
    initialState,
    reducers: {
        setUserDetailInhouse: (state, action) => {
            state.userDetailInhouse = action.payload;
            // You can also update localStorage here if needed
        },
        clearUserDetailInhouse: (state) => {
            state.userDetailInhouse = null;
            // You can also update localStorage here if needed
        },
    },
});

export const { setUserDetailInhouse, clearUserDetailInhouse } = localSlice.actions;

export default localSlice.reducer;