import React, { useState, useEffect, useMemo, useCallback} from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Resources/css/results-style.css'

function formatDateAndTime(isoDate) {
  const dateOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  };

  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const formattedDate = new Date(isoDate);
  const formattedDateString = new Intl.DateTimeFormat('en-US', dateOptions).format(formattedDate);
  const formattedTimeString = new Intl.DateTimeFormat('en-US', timeOptions).format(formattedDate);

  return { formattedDateString, formattedTimeString };
}

function convertMinutesToHoursAndMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours} H ${remainingMinutes} M`;
}

export default function FlightList() {
  const location = useLocation();
  const { responseData, formData } = location.state || {};
  console.log(responseData)
  const flightResult = useMemo(() => responseData?.flightItineraries || [], [responseData]);
  const [filteredData, setFilteredData] = useState( flightResult );

  const siteCurrencyMap = {
    "USD": "$",
    "GBP": "£",
    "CAD": "CAD"
  };

  const selectedSiteId = flightResult[0]?.price?.currencyCode;
  const currency = siteCurrencyMap[selectedSiteId] || "$";
  //console.log(currency)

  return (
    <div>
    {responseData.responsStatus.status === 0 ? (
        <h1>NO RESULTS FOUND FOR SELECTED ROUTES</h1>
    ) : null}
      {filteredData.map((flight, index) => (
        <div id="collapseOne" class="flight-detail-container panel-collapse">                        
        <div class="flight-detail">
            
            <div class="flight-detail-head">
                <div class="head-depar">Departure</div>
                
            </div>
            
            
            {flight.leg1.segments.map((segment, index) => (
            <div class="flight-detail-row">
            <div class="mobile-respons">
            <div class="flight-detail-airline">
                <img src={`https://imgfolders.com/farehutz/${segment.airlineCode}.png`} alt={segment.airlineCode} />
                <div class="airline-name-fno">
                    <div class="airlineName">{segment.airlineCode}</div>
                    <div class="flightno">Flight No. {segment.flightNumber} </div>
                </div>
            </div>
            </div>
            
            <div class="mobile-respons-next">
            <div class="flight-detail-from-to" style={{borderBottom: '1px solid #ddd'}}>
                
      <div class="flight-detail-from" style={{width: '160px'}}>
     <div class="fdfrom-city"><strong>{segment.departureAirportCode}</strong></div>
     <div class="fdfrom-time"><strong>{formatDateAndTime(segment.departureDateTime).formattedTimeString}</strong> {formatDateAndTime(segment.departureDateTime).formattedDateString}</div>
    </div>
                
                <div class="flight-ddinfo-durastop">
                    <div class="flight-duration"></div>
                    <div class="flight-Detailsstop-graph"></div>
                    {/* <div class="flight-stop">1<sup>st</sup> Layover (20h 30m)</div> */}
                    </div>
                
                <div class="flight-detail-to" style={{width: '160px'}}>
                    <div class="fdfrom-city"><strong>{segment.arrivalAirportCode}</strong></div>
                    <div class="fdfrom-time"><strong>{formatDateAndTime(segment.arrivalDateTime).formattedTimeString}</strong> {formatDateAndTime(segment.arrivalDateTime).formattedDateString}</div>
                    {/* <p class="arrivnext">Arrivel next day</p> */}
                </div>
                
                
            </div> 
            </div>
            {/* <!--<div class="flight-detail-cabin">jawed</div>--> */}
            
        </div>
            ))}
            <div class="flight-detail-footer">								
                {/* <div class="head-Baggage">
                    <p>Total Trip Duration: 12h 15m</p>
                </div> */}
            </div>
            <div class="vertical-line-flights"></div>
        </div>
            
            
    <div class="flight-detail"> 
            
            <div class="flight-detail-head">
                <div class="head-depar">Return</div>
            </div>
            {flight?.leg2?.segments.map((segment, index) => (
           <div class="flight-detail-row">
           <div class="mobile-respons">
           <div class="flight-detail-airline">
               <img src={`https://imgfolders.com/farehutz/${segment.airlineCode}.png`} alt={segment.airlineCode} />
               <div class="airline-name-fno">
                   <div class="airlineName">{segment.airlineCode}</div>
                   <div class="flightno">Flight No. {segment.flightNumber} </div>
               </div>
           </div>
           </div>
           
           <div class="mobile-respons-next">
           <div class="flight-detail-from-to" style={{borderBottom: '1px solid #ddd'}}>
               
     <div class="flight-detail-from" style={{width: '160px'}}>
    <div class="fdfrom-city"><strong>{segment.departureAirportCode}</strong></div>
    <div class="fdfrom-time"><strong>{formatDateAndTime(segment.departureDateTime).formattedTimeString}</strong> {formatDateAndTime(segment.departureDateTime).formattedDateString}</div>
   </div>
               
               <div class="flight-ddinfo-durastop">
                   <div class="flight-duration"></div>
                   <div class="flight-Detailsstop-graph"></div>
                   {/* <div class="flight-stop">1<sup>st</sup> Layover (20h 30m)</div> */}
                   </div>
               
               <div class="flight-detail-to" style={{width: '160px'}}>
                   <div class="fdfrom-city"><strong>{segment.arrivalAirportCode}</strong></div>
                   <div class="fdfrom-time"><strong>{formatDateAndTime(segment.arrivalDateTime).formattedTimeString}</strong> {formatDateAndTime(segment.arrivalDateTime).formattedDateString}</div>
                   {/* <p class="arrivnext">Arrivel next day</p> */}
               </div>
               
               
           </div> 
           </div>
           {/* <!--<div class="flight-detail-cabin">jawed</div>--> */}
           
       </div>
            ))}
            
            
            
            <div class="flight-detail-footer">								
                <div class="head-Baggage">
                <div class="flight-price-select">
							<div class="flight-ppp">
						{/* <div class="baggage-dot">
						{/* <img src="resources/images/icons/carryon_bag.png" width="30" height="30" alt=""/>  
							 {/* <input type="text" style={{width: '20px', textAlign: 'center', border: '0px'}} /> 
								</div>								 */}
						{/* <div class="nextBag">
								<img src="resources/images/icons/bag.png" width="20" height="40" alt=""/> 
								 <input type="text" style={{width: '20px', textAlign: 'center', border: '0px'}} />
								</div> */}
								
							</div>
							<div class="flight-ppp">{flight?.leg2?.segments[0].cabin}</div>
							<div class="flight-price">{currency}{flight.price.totalAmount}</div>							
					        <button type="button"><a href={flight.deeplinkUrl} target='_blank'>SELECT FLIGHT</a></button>
							
								
                        </div>
						        
                </div>
            </div>
            
            
        </div>		
            
            
    </div>
      
      ))}
    </div>
  )
}
