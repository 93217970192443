/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Login from '../components/Login/Login';
import AdminControl from '../components/Admin/Admin';
import User from '../components/User/User';
import Flightsearchresult from '../components/Pages/Flight-search-result'


const Routers = () => {
 
   return (
      <>
      <Routes>
         <Route path='/home' element={<Navigate to='/'/>} />
         <Route path='/login' element={<Login />} />
         <Route path='/' element={<AdminControl />} />
         <Route path='/user' element={<User />} />
         <Route path='/flight-list' element={<Flightsearchresult />} />
      </Routes>
      </>
   )
}

export default Routers