import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Engine from '../searchEngine/Engine'
import axios from 'axios';
import {
  Button,
  Grid,
  Box,
  Typography,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Tab
} from '@mui/material';
import { constant } from '../../constant';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const AdminControl = () => {
  const [users, setUsers] = useState([]);
  const [websites, setWebsites] = useState([]);
  const [newUser, setNewUser] = useState({ username: '', email: '', password: '', role: 'User' });
  const [newWebsite, setNewWebsite] = useState({ url: '', companyId: '', allowedUsers: [], role: 'SuperAdmin' });
  const [value, setValue] = useState(0);
  
  const navigate = useNavigate();
  const tokenInhouse = localStorage.getItem('tokenInhouse');
  const userDetailInhouse = JSON.parse(localStorage.getItem("userDetailInhouse"));
  const [userID, setUserID] = useState(userDetailInhouse?.role);
  // Check if logged in and fetch initial data
  useEffect(() => {
    const tokenInhouse = localStorage.getItem('tokenInhouse');
    const userDetailInhouse = JSON.parse(localStorage.getItem("userDetailInhouse"));
    console.log(tokenInhouse)
    console.log(userDetailInhouse)
    
    
    if (!tokenInhouse || !userDetailInhouse) {
      navigate('/login'); // Redirect to login if not authenticated
    }

    if (userDetailInhouse?.role !== 'SuperAdmin'){
      navigate('/user')
    }

    const fetchUsersAndWebsites = async () => {
      try {
        const userResponse = await axios.get(`${constant.baseUrl}api/users/`, {
          headers: { Authorization: `Bearer ${tokenInhouse}` },
        });
        console.log(userResponse)
        
        setUsers(userResponse.data.user);
        console.log("users", userID)
        const websiteResponse = await axios.get(`${constant.baseUrl}api/websites/${userID}`, {
          headers: { Authorization: `Bearer ${tokenInhouse}` },
        });
        console.log("Web",websiteResponse)
        
        setWebsites(websiteResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchUsersAndWebsites();
  }, [navigate]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      //const token = localStorage.getItem('token');
      await axios.post(`${constant.baseUrl}api/users/register`, newUser, {
        headers: { Authorization: `Bearer ${tokenInhouse}` },
      });
      setNewUser({ username: '', email: '', password: '', role: 'User', websiteName: '' });
      alert('User added successfully!');
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  const handleAddWebsite = async (e) => {
    e.preventDefault();
    console.log('Role before sending:', newWebsite.role);
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${constant.baseUrl}api/websites`, newWebsite, {
        headers: { Authorization: `Bearer ${tokenInhouse}` },
      });
      setNewWebsite({ url: '', name: '', partner: '', partnerPassword: '', currency: '', role: 'SuperAdmin', /*, companyId: '', allowedUsers: [] */});
      alert('Website added successfully!');
    } catch (error) {
      console.error('Error adding website:', error);
    }
  };
  

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Admin Control Panel
      </Typography>
      <Tabs value={value} onChange={handleTabChange} aria-label="admin control tabs">
        <Tab label="Add New User" />
        <Tab label="Add New Website" />
        <Tab label="Users List" />
        <Tab label="Websites List" />
        <Tab label="Search Engine" />
      </Tabs>
      <TabPanel value={value} index={0}>
      {/* Add New User Form */}
      <Box component="form" onSubmit={handleAddUser} sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Add New User
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Username"
              fullWidth
              value={newUser.username}
              onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              type="email"
              fullWidth
              value={newUser.email}
              onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
          <TextField
            select
              label="Allowed Domain"
              type="text"
              fullWidth
              value={newUser.websiteName}
              onChange={(e) => setNewUser({ ...newUser, websiteName: e.target.value })}
            >
              {websites.map((website) => (
          <MenuItem key={website} value={website.name}>{website.name}</MenuItem>
          ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Password"
              type="password"
              fullWidth
              value={newUser.password}
              onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Role"
              select
              fullWidth
              value={newUser.role}
              onChange={(e) => setNewUser({ ...newUser, role: e.target.value })}
            >
              <MenuItem value="User">User</MenuItem>
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="SuperAdmin">SuperAdmin</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" fullWidth>
              Add User
            </Button>
          </Grid>
        </Grid>
      </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
      {/* Add New Website Form */}
      <Box component="form" onSubmit={handleAddWebsite} sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Add New Website
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Website URL"
              fullWidth
              value={newWebsite.url}
              onChange={(e) => setNewWebsite({ ...newWebsite, url: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Domain Name ex:'farehutz'"
              fullWidth
              value={newWebsite.name}
              onChange={(e) => setNewWebsite({ ...newWebsite, name: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="partner name"
              fullWidth
              value={newWebsite.partner}
              onChange={(e) => setNewWebsite({ ...newWebsite, partner: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="partner password"
              fullWidth
              value={newWebsite.partnerPassword}
              onChange={(e) => setNewWebsite({ ...newWebsite, partnerPassword: e.target.value })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Currency"
              fullWidth
              value={newWebsite.currency}
              onChange={(e) => setNewWebsite({ ...newWebsite, currency: e.target.value.toUpperCase() })}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField
              label="Company ID"
              fullWidth
              value={newWebsite.companyId}
              onChange={(e) => setNewWebsite({ ...newWebsite, companyId: e.target.value })}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Button type="submit" variant="contained" fullWidth>
              Add Website
            </Button>
          </Grid>
        </Grid>
      </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
      {/* User List */}
      <Typography variant="h6" gutterBottom>
        Users List
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={3}>
      {/* Website List */}
      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        Websites List
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Website URL</TableCell>
              <TableCell>Company ID</TableCell>
              <TableCell>Allowed Users</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {websites.map((website) => (
              <TableRow key={website._id}>
                <TableCell>{website.url}</TableCell>
                <TableCell>{website.companyId}</TableCell>
                {/* <TableCell>
                  {website.allowedUsers.map((user) => user.username).join(', ')}
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </TabPanel>
      <TabPanel value={value} index={4}>
      <Engine websiteData={websites} />
      </TabPanel>
    </Box>
  );
};

export default AdminControl;
