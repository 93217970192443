import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Engine from '../searchEngine/Engine';
import { constant } from '../../constant';
import axios from 'axios';

export default function User() {
    const tokenInhouse = localStorage.getItem('tokenInhouse');
    const userDetailInhouse = JSON.parse(localStorage.getItem("userDetailInhouse"));
    const [userID, setUserID] = useState(userDetailInhouse?.websiteName);
    const [websites, setWebsites] = useState([]);
    const navigate = useNavigate()
    useEffect(() => {
      // Redirect to login if token or user details are missing
      if (!tokenInhouse || !userDetailInhouse) {
          navigate('/login');
          return;
      }

      // Set userID based on role
      if (userDetailInhouse.role === "SuperAdmin") {
          setUserID(userDetailInhouse.role);  
      } else {
          setUserID(userDetailInhouse.websiteName);  
      }
  }, [navigate, tokenInhouse, userDetailInhouse]);
    useEffect(() => {
        if (userDetailInhouse?.role === "SuperAdmin") {
          setUserID(userDetailInhouse?.role);  // Assuming the SuperAdmin userID is stored in _id
        } else {
          setUserID(userDetailInhouse?.websiteName);  // Set userID as websiteName if not SuperAdmin
        }
      }, [userDetailInhouse]);
    
    useEffect(() => {
      // Only call the API if token and user details are available
      if (tokenInhouse && userDetailInhouse) {
          fetchUsersAndWebsites();
      }
  }, [userID]);
    const fetchUsersAndWebsites = async () => {
        try {
          const websiteResponse = await axios.get(`${constant.baseUrl}api/websites/${userID}`, {
            headers: { Authorization: `Bearer ${tokenInhouse}`},
          });
          console.log("Web",websiteResponse)
          
          setWebsites(websiteResponse.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    // useEffect(() => {
    //     fetchUsersAndWebsites()
    // },[navigate, tokenInhouse, userDetailInhouse])

  return (
    <div><Engine websiteData={websites} /></div>
  )
}
