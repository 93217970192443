// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { TextField, Button, Grid, Box, MenuItem } from '@mui/material';
// import { LocalizationProvider } from '@mui/x-date-pickers';
// //import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { DateRangePicker } from '@mui/x-date-pickers/DateRangePicker';
// import { useNavigate } from 'react-router-dom';

// const Engine = ({ userId }) => {
//     const navigate = useNavigate()
//   const [tripType, setTripType] = useState('roundTrip');
//   const [websites, setWebsites] = useState([]);
//   const [selectedWebsite, setSelectedWebsite] = useState('');
//   const [dateRange, setDateRange] = useState([null, null]);

//   useEffect(() => {
//     const fetchWebsites = async () => {
//       try {
//         const response = await axios.get(`/api/websites/${userId}`, {
//           headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
//         });
//         setWebsites(response.data);
//       } catch (error) {
//         console.error('Error fetching websites:', error);
//       }
//     };

//     fetchWebsites();
//   }, [userId]);

//   const handleSearch = () => {
//     console.log('Search initiated with:', { tripType, selectedWebsite, dateRange });
//   };
//   useEffect(() => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       navigate('/login');  // redirect to login if no token
//     }
//   }, []);

//   return (
//     <LocalizationProvider /*dateAdapter={AdapterDateFns}*/>
//       <Box sx={{ padding: 2 }}>
//         <Grid container spacing={2}>
//           <Grid item xs={12}>
//             <TextField
//               select
//               fullWidth
//               label="Website"
//               value={selectedWebsite}
//               onChange={(e) => setSelectedWebsite(e.target.value)}
//             >
//               {websites.map((website) => (
//                 <MenuItem key={website._id} value={website.url}>
//                   {website.url}
//                 </MenuItem>
//               ))}
//             </TextField>
//           </Grid>

//           <Grid item xs={12}>
//             <DateRangePicker
//               startText="Departure"
//               endText="Return"
//               value={dateRange}
//               onChange={(newValue) => setDateRange(newValue)}
//               renderInput={(startProps, endProps) => (
//                 <>
//                   <TextField {...startProps} fullWidth />
//                   <Box sx={{ mx: 2 }}>to</Box>
//                   <TextField {...endProps} fullWidth />
//                 </>
//               )}
//             />
//           </Grid>

//           <Grid item xs={12}>
//             <Button variant="contained" onClick={handleSearch} fullWidth>
//               Search
//             </Button>
//           </Grid>
//         </Grid>
//       </Box>
//     </LocalizationProvider>
//   );
// };

// export default Engine;

















import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Grid, Box, IconButton, Checkbox, FormControlLabel, MenuItem, Popper, List, ListItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { constant } from "../../constant";
import { useNavigate } from 'react-router-dom';
import { XMLParser } from 'fast-xml-parser';
import Spinner from "../spinner/Spinner";

const formatDate = (date) => {
  return date ? format(date, 'yyyy-MM-dd') : '';
};

const Engine = ({ userId, websiteData }) => {
  const [tripType, setTripType] = useState('2'); // Set Round Trip as the default
  const [passenger, setPassenger] = useState({ adult: 1, child: 0, infant: 0, infantWithSeat: 0, cabinType: 'Economy' });
  const [passengerDropdownOpen, setPassengerDropdownOpen] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [selectedWebsite, setSelectedWebsite] = useState('');
  const [selectedPartner, setSelectedPartner] = useState('');
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  //const [baseURL, setBaseURL] = useState('')
  const [formValues, setFormValues] = useState({
    searchID: '', // Default value or dynamic value
    client: 2,
    segment: [
      {
        originAirport: '',
        destinationAirport: '',
        travelDate: '',
        originCountry: 'US',
        destinationCountry: 'ALL',
      },
      {
        originAirport: '',
        destinationAirport: '',
        travelDate: '',
      },
    ],
    searchDirectFlight: false,
    flexibleSearch: false,
    tripType: '2', // Default to round trip
    adults: 1,
    child: 0,
    infants: 0,
    infantsWs: 0,
    cabinType: 'Economy',
    airline: 'All',
    currencyCode: 'USD',
    siteId: 1,
    source: 'Online',
    media: '',
    sID: '',
    rID: '',
    locale: 'en',
    isNearBy: false,
    limit: 200,
    userIP: '',
    serverIP: '',
    device: '',
    browser: '', // Define browserName if necessary
    partner: '',
    website: '',
    partnerPassword: ''
  });

  const [websites, setWebsites] = useState([]);
  const [partners, setPartners] = useState([]);
  const token = localStorage.getItem('tokenInhouse')
  useEffect(() => {
    const fetchWebsites = async () => {
      try {
        const response = await axios.get(`${constant.baseUrl}api/websites/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        console.log(response)
        setWebsites(response.data);
      } catch (error) {
        console.error('Error fetching websites:', error);
      }
    };

    fetchWebsites();
  }, [userId]);

  const handlePassengerChange = (field, delta) => {
    setPassenger((prev) => {
      const newValue = Math.max(prev[field] + delta, 0); // Ensure no negative values
      setFormValues((prevValues) => ({
        ...prevValues,
        [field]: newValue,
      }));
      return {
        ...prev,
        [field]: newValue,
      };
    });
  };

  const handleCabinTypeChange = (event) => {
    const cabinType = event.target.value;
    setPassenger((prev) => {
      setFormValues((prevValues) => ({
        ...prevValues,
        cabinType: cabinType,
      }));
      return {
        ...prev,
        cabinType: cabinType,
      };
    });
  };
  const [APIData, setAPIData] = useState([]);
  const [filteredOriginResults, setFilteredOriginResults] = useState([]);
  const [filteredDestinationResults, setFilteredDestinationResults] = useState([]);

  const [originInputRef, setOriginInputRef] = useState(null);
  const [destinationInputRef, setDestinationInputRef] = useState(null);

  const handleFieldChange = (fieldPath, value) => {
    const keys = fieldPath.split('.');
    setFormValues((prevValues) => {
      let updatedValues = { ...prevValues };
      let currentLevel = updatedValues;
      for (let i = 0; i < keys.length - 1; i++) {
        currentLevel = currentLevel[keys[i]];
      }
      currentLevel[keys[keys.length - 1]] = value;
  
      // Check if we are modifying the first segment's origin or destination
      if (fieldPath === 'segment.0.originAirport') {
        // Update return segment's destination airport
        updatedValues.segment[1].destinationAirport = value;
      } else if (fieldPath === 'segment.0.destinationAirport') {
        // Update return segment's origin airport
        updatedValues.segment[1].originAirport = value;
      }
      if (fieldPath === 'partner') {
        const selectedPartner = partners.find((partner) => partner.name === value);
        setFormValues((prev) => ({ ...prev, partnerPassword: selectedPartner.password }));
      }
  
      return updatedValues;
    });
  };
  

  const handleDateChange = (newValue) => {
    setDateRange(newValue);
    if (tripType === '2') {
      setFormValues((prevValues) => ({
        ...prevValues,
        segment: [
          {
            ...prevValues.segment[0],
            travelDate: newValue[0] ? formatDate(newValue[0]) : '',
          },
          {
            ...prevValues.segment[1],
            travelDate: newValue[1] ? formatDate(newValue[1]) : '',
          },
        ],
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        segment: [
          {
            ...prevValues.segment[0],
            travelDate: newValue[0] ? formatDate(newValue[0]) : '',
          },
          {
            originAirport: '',
            destinationAirport: '',
            travelDate: '',
          },
        ],
      }));
    }
  };

  const handleTripTypeChange = (type) => {
    setTripType(type);
    console.log(tripType)
    if (type === '1') {
      // Clear return segment if One Way
      setFormValues((prevValues) => ({
        ...prevValues,
        tripType: '1',
        segment: [
          { ...prevValues.segment[0] },
          {
            originAirport: '',
            destinationAirport: '',
            travelDate: '',
          },
        ],
      }));
    } else {
      // Pre-fill return segment if Round Trip
      setFormValues((prevValues) => ({
        ...prevValues,
        tripType: '2',
        segment: [
          { ...prevValues.segment[0] },
          {
            originAirport: prevValues.segment[0].destinationAirport,
            destinationAirport: prevValues.segment[0].originAirport,
            travelDate: dateRange[1] ? formatDate(dateRange[1]) : '',
          },
        ],
      }));
    }
  };

  const fetchData = async (searchValue) => {
    // Try to get cached data specific to the search value
    const cachedData = localStorage.getItem(`cachedData_${searchValue}`);
  
    if (cachedData) {
      const parsedData = JSON.parse(cachedData);
      return parsedData;
    } else {
      try {
        const response = await axios.get(`https://www.tgsapi.com/flights/CrossAirportList?data=${searchValue}&authcode=farehuts`);
        const fetchedData = response.data;
  
        // Cache the data specific to the search value
        localStorage.setItem(`cachedData_${searchValue}`, JSON.stringify(fetchedData));
        return fetchedData;
      } catch (error) {
        console.error("API fetch error:", error);
        return [];
      }
    }
  };
  
  const handleSearch = async (searchValue, isOrigin) => {
    if (searchValue.length > 2) { 
      // Fetch data synchronously
      const data = await fetchData(searchValue);
  
      const matchingAirportCode = data.find(
        (item) => item.airportCode.toLowerCase() === searchValue.toLowerCase()
      );
  
      const filteredByName = data.filter(
        (item) =>
          item.airportCode.toLowerCase() !== searchValue.toLowerCase() &&
          (item.airportName.toLowerCase().includes(searchValue.toLowerCase()) ||
           item.cityName.toLowerCase().includes(searchValue.toLowerCase()))
      ).slice(0, 9);
  
      const filteredResults = matchingAirportCode ? [matchingAirportCode, ...filteredByName] : filteredByName;
  
      if (isOrigin) {
        setFilteredOriginResults(filteredResults);
      } else {
        setFilteredDestinationResults(filteredResults);
      }
    } else {
      if (isOrigin) {
        setFilteredOriginResults([]);
      } else {
        setFilteredDestinationResults([]);
      }
    }
  };
  
  

  const handleSelect = (item, isOrigin) => {
    if (isOrigin) {
      handleFieldChange('segment.0.originAirport', item.airportCode);
      setFilteredOriginResults([]);
    } else {
      handleFieldChange('segment.0.destinationAirport', item.airportCode);
      setFilteredDestinationResults([]);
    }
  };

  const togglePassengerDropdown = () => {
    setPassengerDropdownOpen(!passengerDropdownOpen);
  };

  useEffect(() => {
    const partnerList = websiteData.find((website) => website.name === selectedWebsite)?.partner || [];
    setPartners(partnerList);
  }, [selectedWebsite, websiteData]);

  useEffect(() => {
    const selectedWebsiteData = websiteData.find((website) => website.url === selectedWebsite);
    const currencyCode = selectedWebsiteData?.currency || 'USD'
    const partnerPassword = selectedWebsiteData?.partnerPassword || '';
    console.log("PP",partnerPassword)
    setFormValues((prevValues) => ({
      ...prevValues,
      website: selectedWebsite,
      partner: selectedPartner,
      partnerPassword: partnerPassword,
      currencyCode: currencyCode,
    }));
  }, [selectedWebsite, selectedPartner]);
  
  const getCabinTypeLabel = (cabinTypeValue) => {
    switch (cabinTypeValue) {
      case "Economy":
        return "Y";
      case "Premium Economy":
        return "F";
      case "Business":
        return "C";
      case "First Class":
        return "F";
      default:
        return "";
    }
  };

  const handleSearchClick = async () => {
    console.log(formValues);
    try {
      setIsLoading(true);
      //const tripType = ; // Check if it's a round trip
      //const baseApiUrl = tripType ? selectedWebsite : baseURL; // Choose the correct base URL
      const cabinTypeLabel = getCabinTypeLabel(formValues.cabinType); // Assuming `getCabinTypeLabel` is defined elsewhere
  
      // Determine the middle URL based on the selected partner
      const middleUri = formValues.partner === "jetCost" ? "jetfares" : "metaSearch";
  
      // Construct the URL based on trip type
      const apiUrl = formValues.tripType === "2"
        ? `${selectedWebsite}/FlightEngine/Service/${middleUri}/?from=${formValues.segment[0].originAirport}&to=${formValues.segment[0].destinationAirport}&date1=${formValues.segment[0].travelDate}&date2=${formValues.segment[1].travelDate}&adults=${formValues.adults}&children=${formValues.child}&infants=${formValues.infants}&cabin=${cabinTypeLabel}&partner=${formValues.partner}&password=${formValues.partnerPassword}&locale=en&currency=${formValues.currencyCode}`
        : `${selectedWebsite}/FlightEngine/Service/${middleUri}/?from=${formValues.segment[0].originAirport}&to=${formValues.segment[0].destinationAirport}&date1=${formValues.segment[0].travelDate}&adults=${formValues.adults}&children=${formValues.child}&infants=${formValues.infants}&cabin=${cabinTypeLabel}&partner=${formValues.partner}&password=${formValues.partnerPassword}&locale=en&currency=${formValues.currencyCode}`;
      console.log(apiUrl)
      // Make the API call
      const response = await axios.get(apiUrl);
      const contentType = response.headers['content-type'];

      let responseData;
  
      // Handle the response
      if (contentType.includes('application/json')) {
        responseData = response.data; // JSON response
      } else if (contentType.includes('application/xml') || contentType.includes('text/xml')) {
        // Convert XML to JSON using fast-xml-parser
        const parser = new XMLParser();
        responseData = parser.parse(response.data);
      }
  
      if (responseData) {
        setIsLoading(false);
        setResponseData(responseData);
        const mergedData = {
          responseData,
          formData: formValues,
        };
        navigate(`/flight-list`, { state: mergedData });
      } else {
        alert("The requested site/route is not available on the meta.");
      }
    } catch (error) {
      console.error("Error during search:", error);
      setIsLoading(false);
      //setErrorMessage("An error occurred during the search. Please try again.");
    }
  };
  

  console.log("HelloInSearch", filteredOriginResults)
  console.log(filteredDestinationResults)
  useEffect(() => {
    console.log("APIData:", APIData);
  }, [APIData]);
  
  useEffect(() => {
    console.log("Filtered Origin Results:", filteredOriginResults);
    console.log("Filtered Destination Results:", filteredDestinationResults);
  }, [filteredOriginResults, filteredDestinationResults]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
  <Box sx={{ padding: 2, maxWidth: 600, margin: 'auto' }}>
    {/* Trip Type Buttons */}
    <Box display="flex" justifyContent="space-between" mb={2}>
      <Button
        variant={tripType === '1' ? 'contained' : 'outlined'}
        onClick={() => handleTripTypeChange('1')}
      >
        One Way
      </Button>
      <Button
        variant={tripType === '2' ? 'contained' : 'outlined'}
        onClick={() => handleTripTypeChange('2')}
      >
        Round Trip
      </Button>
    </Box>

    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Origin"
          variant="outlined"
          value={formValues.segment[0].originAirport}
          onChange={(e) => {handleFieldChange('segment.0.originAirport', e.target.value); handleSearch(e.target.value, true)}}
          onFocus={() => setFilteredOriginResults([])}
          inputRef={(ref) => setOriginInputRef(ref)}
        />
        <Popper open={filteredOriginResults.length > 0} anchorEl={originInputRef} placement="bottom-start" sx={{ zIndex: 1300 }}>
            <Box sx={{ border: 1, borderColor: 'gray', borderRadius: 1, bgcolor: 'white' }}>
              <List>
                {filteredOriginResults.map((item) => (
                  <ListItem button key={item.airportCode} onClick={() => handleSelect(item, true)}>
                    {item.airportName} ({item.airportCode}, {item.cityName})
                  </ListItem>
                ))}
              </List>
            </Box>
        </Popper>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Destination"
          variant="outlined"
          value={formValues.segment[0].destinationAirport}
          onChange={(e) => {handleFieldChange('segment.0.destinationAirport', e.target.value); handleSearch(e.target.value, false)}}
          onFocus={() => setFilteredDestinationResults([])}
          inputRef={(ref) => setDestinationInputRef(ref)}
        />
        <Popper open={filteredDestinationResults.length > 0} anchorEl={destinationInputRef} placement="bottom-start" sx={{ zIndex: 1300 }}>
            <Box sx={{ border: 1, borderColor: 'gray', borderRadius: 1, bgcolor: 'white' }}>
              <List>
                {filteredDestinationResults.map((item) => (
                  <ListItem button key={item.airportCode} onClick={() => handleSelect(item, false)}>
                    {item.airportName} ({item.airportCode}, {item.cityName})
                  </ListItem>
                ))}
              </List>
            </Box>
            </Popper>
      </Grid>

      {/* Date Range Picker for Round Trip */}
      <Grid item xs={12}>
        <DateRangePicker
          startText="From Date"
          endText={tripType === '2' ? 'Return Date' : 'To Date'}
          value={dateRange}
          onChange={handleDateChange}
          calendars={2} // Show two months
          renderInput={(startProps, endProps) => (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField {...startProps} fullWidth variant="outlined" InputLabelProps={{ shrink: true }} />
              </Grid>
              {tripType === '2' ? (
                <Grid item xs={6}>
                  <TextField {...endProps} fullWidth variant="outlined" InputLabelProps={{ shrink: true }} />
                </Grid>
              ) : null}
            </Grid>
          )}
        />
      </Grid>

      <Grid item xs={12}>
            <Box>
              <Button
                variant="outlined"
                fullWidth
                onClick={togglePassengerDropdown}
              >
                {`${passenger.adult + passenger.child + passenger.infant + passenger.infantWithSeat} pax / ${passenger.cabinType}`}
              </Button>
              {passengerDropdownOpen && (
                <Box sx={{ marginTop: 1, padding: 2, border: '1px solid #ccc', borderRadius: 1 }}>
                  <Grid container spacing={2} alignItems="center">
                    {['adult', 'child', 'infant', 'infantWithSeat'].map((type) => (
                      <Grid item xs={6} key={type}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <span>{type.charAt(0).toUpperCase() + type.slice(1)}</span>
                          <Box>
                            <IconButton onClick={() => handlePassengerChange(type, -1)} size="small">
                              <RemoveIcon />
                            </IconButton>
                            <span>{passenger[type]}</span>
                            <IconButton onClick={() => handlePassengerChange(type, 1)} size="small">
                              <AddIcon />
                            </IconButton>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                  <Box mt={2}>
                    {['Economy', 'Premium Economy', 'Business', 'First Class'].map((cabin) => (
                      <FormControlLabel
                        key={cabin}
                        control={
                          <Checkbox
                            checked={passenger.cabinType === cabin}
                            onChange={handleCabinTypeChange}
                            value={cabin}
                          />
                        }
                        label={cabin}
                      />
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>

      {/* Select Website */}
      <Grid item xs={12}>
      
        <TextField
          select
          fullWidth
          label="Select Website"
          value={selectedWebsite}
          onChange={(e) => {
            setSelectedWebsite(e.target.value);
            handleFieldChange('siteId', e.target.value);
          }}
        >
          {websiteData.map((website) => (
          <MenuItem key={website} value={website.url}>{website.url}</MenuItem>
          ))}
          {/* Add more website options as needed */}
        </TextField>
      
      </Grid>

      {/* Select Partner */}
      <Grid item xs={12}>
        <TextField
          select
          fullWidth
          label="Select Partner"
          value={selectedPartner}
          onChange={(e) => {
            setSelectedPartner(e.target.value);
            handleFieldChange('partnerId', e.target.value);
          }}
        >
          {websiteData.map((website) => (
          <MenuItem key={website} value={website.partner}>{website.partner}</MenuItem>
        ))}
          {/* Add more partner options as needed */}
        </TextField>
      </Grid>

      {/* Search Button */}
      <Grid item xs={12}>
        <Button variant="contained" fullWidth onClick={handleSearchClick}>
        {isLoading ? <Spinner /> : "Search"}
        </Button>
      </Grid>
    </Grid>
  </Box>
</LocalizationProvider>

  );
};

export default Engine;